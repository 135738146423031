<template>
  <fieldset>
    <legend class="text-lg font-medium text-gray-900">{{ title }}</legend>
    <div
      class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200"
    >
      <div
        v-for="(info, infoIdx) in data"
        :key="infoIdx"
        class="relative flex items-start py-4"
      >
        <div class="min-w-0 flex-1 text-sm">
          <label
            :for="`info-${info.value}`"
            class="font-medium text-gray-700 select-none"
            >{{ info.name }}</label
          >
        </div>
        <div class="ml-3 flex items-center h-5">
          <input
            :id="`info-${info.value}`"
            :name="`info-${info.value}`"
            type="checkbox"
            class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
          />
        </div>
      </div>
    </div>
  </fieldset>
</template>

<script>
export default {
  props: ["data", "title"],
  setup() {
    return {};
  },
};
</script>

<template>
 <SuccessBanner
  :key="key"
  :msg="successMsg"
  :active="activeBanner"
  @closeBanner="activeBanner = false"
  :isSuccess="isSuccess"
 />
 <div>
  <PbxHeader
   v-model:search="search"
   pbxMenuType="queueDetail"
   :periodSelector="period"
   :parseDateRange="parseDateRange"
   @select-hostname="selectHostname = $event"
   :selectedQueue="selectedQueue"
   :parseTimeRange="parseTimeRange"
   :timeSelected="selectedtime"
   :buttonSelected="buttonSelected"
   @activeBanner="(activeBanner = true), (key = !key)"
   @successMsg="successMsg = $event"
   @success="isSuccess = $event"
   urlType="queue"
  />
  <div class="mx-6">
   <div class="md:pl-0 pl-10 pr- pt-5">
    <PbxDashboardSelector
     :key="pbxElements"
     @selector="period = $event"
     @button-selector="(...args) => getSelectedButton(...args)"
     @action="
      $emit('action', $event),
       (selectedElement = $event),
       getQueueDetailsDataObj($event),
       getQueueDetailsDataObjComparison($event)
     "
     :selectHostname="selectHostname"
     :urlType="urlType"
     @queueName="selectedQueue = $event"
     :buttonSelected="buttonSelected"
     :elements="selectedElement"
     @timeSelector="selectedtime = $event"
    />
   </div>
   <div class="flex justify-between">
    <div class="inline-flex gap-1">
     <h3 class="text-lg leading-6 font-medium text-gray-900 inline-flex">
      {{ $t("comparisonSamePeriodLastMonth") }}
      <select
       v-model="comparePeriodType"
       id="location"
       name="location"
       class="ml-1 block w-auto -mt-1 pl-2 pr-10 py-1 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md rounded border-transparent text-sm bg-gray-100"
      >
       <option selected="" value="year">{{ $t("year") }}</option>
       <option value="month">{{ $t("month") }}</option>
      </select>
     </h3>
     <div class="justify-end">
      <button
       class="border-2 border-gray-900 rounded-xl"
       @click="showHeaderSelector = !showHeaderSelector"
       @blur="showHeaderSelector = false"
      >
       <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
       >
        <path
         stroke-linecap="round"
         stroke-linejoin="round"
         stroke-width="2"
         d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
        />
       </svg>
      </button>
      <transition
       enter-active-class="transition ease-out duration-100"
       enter-from-class="transform opacity-0 scale-95"
       enter-to-class="transform opacity-100 scale-100"
       leave-active-class="transition ease-in duration-75"
       leave-from-class="transform opacity-100 scale-100"
       leave-to-class="transform opacity-0 scale-95"
      >
       <div
        role="menu"
        tabindex="0"
        v-show="showHeaderSelector || selectorIsActive"
        @focus="selectorIsActive = true"
        @blur="selectorIsActive = false"
        class="mt-0 rounded absolute w-80 shadow-2xl body__header--menu ring-1 ring-black ring-opacity-5 focus:outline-none"
       >
        <fieldset class="space-y-1 p-2">
         <div
          class="relative flex items-start"
          v-for="(header, idx) in comparisonInfo"
          :key="header.name"
         >
          <div class="flex items-center h-5">
           <input
            @input="removeComparison(header.name, idx)"
            :checked="header.show"
            :id="header.name"
            aria-describedby="comments-description"
            name="comments"
            @focus="selectorIsActive = true"
            @blur="selectorIsActive = false"
            type="checkbox"
            class="focus:ring-green-500 h-4 w-4 body__table-header-filter--text border-gray-300 rounded"
           />
          </div>
          <div class="ml-3 text-sm text-left">
           <label :for="header.name" class="font-medium text-gray-700">{{ $t(header.name) }}</label>
          </div>
         </div>
        </fieldset>
        <div class="text-right pb-1 pr-1">
         <button
          @focus="selectorIsActive = true"
          @blur="selectorIsActive = false"
          class="font-medium text-gray-700 -mt-1"
          @click="clearFilters()"
         >
          clear
         </button>
        </div>
       </div>
      </transition>
     </div>
    </div>
    <div class="flex gap-4">
     <SwitchGroup as="div" class="flex items-center gap-1">
      <SwitchLabel as="span" class="flex gap-1 cursor-pointer">
       <span class="text-sm font-medium text-gray-900">{{ $t("showDetailedCallStats") }}</span>
       <!-- <span class="text-sm text-gray-500">20s</span> -->
      </SwitchLabel>
      <Switch
       v-model="detailedCallStats"
       :class="[
        detailedCallStats ? 'bg-indigo-600' : 'bg-gray-200',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
       ]"
      >
       <span
        aria-hidden="true"
        :class="[
         detailedCallStats ? 'translate-x-5' : 'translate-x-0',
         'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        ]"
       />
      </Switch>
      <SwitchLabel as="span" class="flex gap-1">
       <!-- <span class="text-sm font-medium text-gray-900">{{$t('displayAnswerTimeIn')}}</span> -->
       <!-- <span class="text-sm text-gray-500">30s</span> -->
      </SwitchLabel>
     </SwitchGroup>
     <SwitchGroup as="div" class="flex items-center gap-1">
      <SwitchLabel as="span" class="flex gap-1 cursor-pointer">
       <span class="text-sm font-medium text-gray-900">{{ $t("displayAnswerTimeIn") }}</span>
       <span class="text-sm text-gray-500">20s</span>
      </SwitchLabel>
      <Switch
       v-model="enabled"
       :class="[
        enabled ? 'bg-indigo-600' : 'bg-indigo-600',
        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
       ]"
      >
       <span
        aria-hidden="true"
        :class="[
         enabled ? 'translate-x-5' : 'translate-x-0',
         'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        ]"
       />
      </Switch>
      <SwitchLabel as="span" class="flex gap-1">
       <!-- <span class="text-sm font-medium text-gray-900">{{$t('displayAnswerTimeIn')}}</span> -->
       <span class="text-sm text-gray-500">30s</span>
      </SwitchLabel>
     </SwitchGroup>
    </div>
   </div>
   <div class="">
    <StatsComparator v-if="comparatorData.length > 0" :stats="comparatorData" />
   </div>
  </div>
  <div class="flex flex-col" v-if="queueData.length > 0">
   <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
    <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-6">
     <div class="shadow overflow-hidden border-b border-gray-200 rounded-md">
      <queue-detail-table
       :enabled="enabled"
       :tablePaginatedData="queueData"
       :selectedElement="selectedElement"
      />
     </div>
    </div>
   </div>
  </div>
  <div v-else class="pt-20">
   <div v-if="selectedQueue.length > 0" class="text-center">
    <svg
     xmlns="http://www.w3.org/2000/svg"
     class="mx-auto h-12 w-12 text-gray-400"
     fill="none"
     viewBox="0 0 24 24"
     stroke="currentColor"
    >
     <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
     />
    </svg>
    <h3 class="mt-2 text-lg font-medium text-gray-900">
     {{ $t("queueHasNoData") }}
    </h3>
   </div>
  </div>
 </div>
 <Loading
  v-model:active="isLoading"
  :can-cancel="true"
  :on-cancel="onCancel"
  :is-full-page="fullPage"
 />
</template>

<script>
import axios from "axios";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import SuccessBanner from "../../components/SuccessBanner.vue";
import TablePagination from "../../components/TablePagination.vue";
import HorizontalCheckbox from "../../components/HorizontalCheckbox.vue";
import PbxHeader from "../../components/PBX/PbxHeader.vue";
import PbxDashboardSelector from "../../components/PBX/PbxDashboardSelector.vue";
import QueueDetailTable from "../../components/PBX/QueueDetailTable.vue";
import StatsComparator from "../../components/StatsComparator.vue";
import { mapGetters } from "vuex";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";

const comparisonInfo = [
 { value: "abandoned", name: "abandonedCallsCoefficient", show: false },
 { value: "answered", name: "answeredCallsCoefficient", show: true },
 { value: "answered_00_30", name: "answered_00_30", show: false },
 { value: "answered_31_60", name: "answered_31_60", show: false },
 { value: "answered_61_90", name: "answered_61_90", show: false },
 { value: "answered_90+", name: "answered_90+", show: false },
 { value: "answered_elsewhere", name: "answered_elsewhere", show: false },
 { value: "calls", name: "numberOfCalls", show: true },
 //  { value: "dissuaded", name: "dissuadedCallsCoefficient", show: false },
 { value: "duration", name: "averageCallDuration", show: true },
 { value: "redirected_by_agent", name: "transfered", show: false },
 //  {
 //   value: "waiting_time_on_abandoned",
 //   name: "averageDropoutTime",
 //   show: false,
 //  },
 {
  value: "waiting_time_on_answer",
  name: "averageWaitingTime",
  show: false,
 },
 //  {
 //   value: "waiting_time_on_answer_in_queue",
 //   name: "averageWaitingTimeQueue",
 //   show: true,
 //  },
];

export default {
 props: ["urlType"],
 components: {
  Loading,
  PbxHeader,
  PbxDashboardSelector,
  QueueDetailTable,
  TablePagination,
  StatsComparator,
  HorizontalCheckbox,
  SuccessBanner,
  Switch,
  SwitchGroup,
  SwitchLabel,
 },
 data() {
  return {
   detailedCallStats: false,
   enabled: false,
   selectedtime: undefined,
   selectorIsActive: false,
   showHeaderSelector: false,
   comparisonInfo,
   search: "",
   isLoading: false,
   fullPage: true,
   selectedElement: "",
   selectedQueue: "",
   period: "",
   comparePeriod: [],
   selectHostname: "",
   queueData: [],
   textMonth: "",
   buttonSelected: "month",
   comparatorData: [],
   queueDataArr: [],
   comparePeriodType: "year",
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
  };
 },
 methods: {
  setComparePeriod(period) {
   this.comparePeriod = [];
   let year = undefined;
   let month = undefined;
   let day = undefined;
   let splitPeriod = undefined;
   let stringPeriod = "";
   if (!period.includes("-")) {
    year = period.slice(0, 4);
    month = period.slice(4, 6);
    day = period.slice(6, 8);
   } else {
    splitPeriod = period.split("-");
   }
   if (splitPeriod) {
    let periodSplitArray = [];
    let periodSplitString = "";
    for (let index = 0; index < splitPeriod.length; index++) {
     const element = splitPeriod[index];
     year = element.slice(0, 4);
     month = element.slice(4, 6);
     day = element.slice(6, 8);
     let comparePeriod = new Date(year, month - 1, day);
     if (this.comparePeriodType === "month") {
      comparePeriod.setMonth(comparePeriod.getMonth() - 1);
     } else if (this.comparePeriodType === "year") {
      comparePeriod.setFullYear(comparePeriod.getFullYear() - 1);
     }
     let stringYear = comparePeriod.getFullYear();
     let stringMonth = ("0" + (comparePeriod.getMonth() + 1)).slice(-2);
     let stringDay = ("0" + comparePeriod.getDate()).slice(-2);
     stringPeriod = stringYear + stringMonth + stringDay;
     periodSplitArray.push(stringPeriod);
    }
    periodSplitString = periodSplitArray[0] + "-" + periodSplitArray[1];
    this.comparePeriod.push(period, periodSplitString);
   } else {
    let comparePeriod = new Date(year, month - 1, day);
    if (period.length == 6) {
     comparePeriod = new Date(year, month - 1, 20);
    }
    if (this.comparePeriodType === "month") {
     comparePeriod.setMonth(comparePeriod.getMonth() - 1);
    } else if (this.comparePeriodType === "year") {
     comparePeriod.setFullYear(comparePeriod.getFullYear() - 1);
    }
    let stringYear = comparePeriod.getFullYear();
    let stringMonth = ("0" + (comparePeriod.getMonth() + 1)).slice(-2);
    let stringDay = ("0" + comparePeriod.getDate()).slice(-2);
    if (period.length == 6) {
     stringPeriod = stringYear + stringMonth;
    } else if (period.length == 8) {
     stringPeriod = stringYear + stringMonth + stringDay;
    }
    this.comparePeriod.push(period, stringPeriod);
   }
  },
  getSelectedButton(...args) {
   this.buttonSelected = args[0];
   this.textMonth = args[1];
  },
  getButtonSelectedFromQueryPeriod() {
   let period = undefined;
   if (this.$route.query.period) {
    period = this.$route.query.period;
   }
   if (period) {
    if (period.length == 8) {
     this.buttonSelected = "day";
    } else if (period.length == 6) {
     this.buttonSelected = "month";
    } else {
     this.buttonSelected = "week";
    }
   }
  },
  async getQueueDetails(element, sentPeriod) {
   let period = "";
   if (sentPeriod) {
    period = sentPeriod;
   } else {
    period = this.period;
   }
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/pbxData/${
      this.hostName
     }/callQueues?&call_queue=${element}&days=${period}`
    );

    let data = {};
    const keys = Object.keys(res.data);
    if (this.detailedCallStats) {
     data = keys
      .filter((key) => !key.includes("floc"))
      .reduce((obj, key) => {
       obj[key] = res.data[key];
       return obj;
      }, {});
    } else {
     data = keys
      .filter((key) => key.includes("floc") || key.includes("name"))
      .reduce((obj, key) => {
       obj[key.replace("_floc", "")] = res.data[key];
       return obj;
      }, {});
    }
    console.log("file: ExpertStatisticsQueueTableDetail.vue:439 ~ getQueueDetails ~ data", data);
    return data;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  async getQueueDetailsDataObj(event) {
   this.isLoading = true;

   let queuesData = {};
   let queueData = {};

   if (this.pbxElements && event && event.length > 0) {
    for (let index = 0; index < event.length; index++) {
     const elementEvent = event[index];
     queueData = {};
     // this.isLoading = true;
     let data = await this.getQueueDetails(elementEvent);

     if (data && Object.keys(data).length > 0) {
      let dataKeys = Object.keys(data);
      let arrayOfElements = this.getAllElementsFromKeys(dataKeys);
      let keysParameters = this.getAllParametersFromKey(dataKeys);
      queueData["data"] = data;
      queueData["dataKeys"] = dataKeys;
      queueData["arrayOfElements"] = arrayOfElements;
      queueData["keysParameters"] = keysParameters;
      queuesData[elementEvent] = queueData;
     }
    }

    if (Object.keys(queuesData).length > 0) {
     if (this.selectedtime && this.selectedtime.length > 1) {
      this.getQueueDetailsDataObjHourly(queuesData);
     } else {
      this.getQueueDetailsDataObjAll(queuesData);
     }
    }
   } else {
    this.queueData = [];
   }
  },
  getQueueDetailsDataObjHourly(data) {
   let queueTime = undefined;
   let dataArr = [];
   let dataObj = {};
   let elementValue = 0;

   let firsQuarterHour =
    ("0" + (parseInt(this.selectedtime[0].slice(0, 2)) + parseInt(this.hostTimeZone))).slice(-2) +
    this.selectedtime[0].slice(-2);

   let lastQuarterHour =
    ("0" + (parseInt(this.selectedtime[1].slice(0, 2)) + parseInt(this.hostTimeZone))).slice(-2) +
    this.selectedtime[1].slice(-2);

   let keysFromData = Object.keys(data);

   for (let index = 0; index < keysFromData.length; index++) {
    const elementFromData = keysFromData[index];
    let dataForEach = data[elementFromData]["data"];
    let arrayOfElements = data[elementFromData]["arrayOfElements"];
    let dataKeys = data[elementFromData]["dataKeys"];
    let keysParameters = data[elementFromData]["keysParameters"];

    for (let index = 0; index < arrayOfElements.length; index++) {
     dataObj = {};
     const element = arrayOfElements[index];
     if (element === "queue") {
      dataObj["element"] = "Global";
     } else {
      dataObj["element"] = element;
      dataObj["queueName"] = dataForEach["queue:name"];
     }
     for (let index = 0; index < keysParameters.length; index++) {
      elementValue = 0;
      const keysParameter = keysParameters[index];

      dataKeys.forEach((el, index) => {
       let splitEl = el.split(":");

       if (splitEl.length >= 3) {
        let queueElement = splitEl[0];
        let queueDataType = splitEl[1];
        let queuePeriod = splitEl[2] ? splitEl[2] : null;
        let elementName = queueElement + ":" + "name";
        if (queuePeriod) {
         queueTime = queuePeriod.slice(-4);
        }

        if (element == queueElement) {
         dataObj["name"] = dataForEach[elementName];
         if (queueDataType === keysParameter && queueTime) {
          if (
           parseInt(firsQuarterHour) <= parseInt(queueTime) &&
           parseInt(queueTime) <= parseInt(lastQuarterHour)
          ) {
           if (elementValue >= 0) {
            elementValue = elementValue + parseInt(dataForEach[el]);
            dataObj[queueDataType] = elementValue;
           } else {
            dataObj[queueDataType] = 0;
           }
          }
         }
        }
       }
      });
     }
     if (dataObj["element"] == "Global") {
      dataArr.splice(0, 0, dataObj);
     } else {
      dataArr.push(dataObj);
     }
    }
   }

   dataArr = this.reduceGlobalElements(dataArr);

   this.isLoading = false;

   this.queueData = dataArr;
  },
  getQueueDetailsDataObjAll(data) {
   let dataArr = [];
   let dataObj = {};

   let keysFromData = Object.keys(data);

   for (let index = 0; index < keysFromData.length; index++) {
    const elementFromData = keysFromData[index];
    let dataForEach = data[elementFromData]["data"];
    let arrayOfElements = data[elementFromData]["arrayOfElements"];
    let dataKeys = data[elementFromData]["dataKeys"];

    for (let index = 0; index < arrayOfElements.length; index++) {
     dataObj = {};
     const element = arrayOfElements[index];
     if (element === "queue") {
      dataObj["element"] = "Global";
     } else {
      dataObj["queueName"] = dataForEach["queue:name"];
      dataObj["element"] = element;
     }
     dataKeys.forEach((el, index) => {
      let splitEl = el.split(":");
      let queueElement = splitEl[0];
      let queueDataType = splitEl[1];
      let queuePeriod = splitEl[2] ? splitEl[2] : null;
      if (queuePeriod && queuePeriod.length == 12) {
       let queueTime = queuePeriod.slice(-4);
      }
      if (element == queueElement) {
       if (splitEl.length == 2) {
        dataObj[queueDataType] = dataForEach[el];
       }
      }
     });
     if (dataObj["element"] == "Global") {
      dataArr.splice(0, 0, dataObj);
     } else {
      dataArr.push(dataObj);
     }
    }
   }

   dataArr = this.reduceGlobalElements(dataArr);

   this.isLoading = false;

   this.queueData = dataArr;
  },
  reduceGlobalElements(array) {
   let globalDataArray = [];
   let globalDataKeys = [];
   let globalObj = {};
   let dataArr = array;

   for (let index = 0; index < dataArr.length; index++) {
    const element = dataArr[index];
    if (element.element === "Global") {
     globalDataArray.push(element);
     globalDataKeys.push(Object.keys(element));
    }
   }

   globalDataKeys = globalDataKeys.reduce((r, c) => Object.assign(r, c), {});

   let keysForGlobalKeys = Object.values(globalDataKeys);

   for (let index = 0; index < keysForGlobalKeys.length; index++) {
    const element = keysForGlobalKeys[index];

    globalObj[element] = globalDataArray.reduce(function (accumulator, item) {
     if (parseInt(item[element]) >= 0) {
      return parseInt(accumulator) + (item[element] ? parseInt(item[element]) : 0);
     } else {
      return accumulator + (item[element] ? `, ${item[element]}` : "");
     }
    }, 0);
   }

   dataArr.splice(0, globalDataArray.length, globalObj);

   return dataArr;
  },
  reduceElementsComparisonObj(array) {
   let globalDataArray = [];
   let globalDataKeys = [];
   let globalObj = {};
   let dataArr = array;

   for (let index = 0; index < dataArr.length; index++) {
    const element = dataArr[index];
    globalDataArray.push(element);
    globalDataKeys.push(Object.keys(element));
   }

   globalDataKeys = globalDataKeys.reduce((r, c) => Object.assign(r, c), {});

   let keysForGlobalKeys = Object.values(globalDataKeys);

   for (let index = 0; index < keysForGlobalKeys.length; index++) {
    const element = keysForGlobalKeys[index];

    globalObj[element] = globalDataArray.reduce(function (accumulator, item) {
     if (element !== "element" && element !== "period") {
      if (parseInt(item[element]) >= 0) {
       return accumulator + parseInt(item[element]);
      } else {
       return accumulator + "," + item[element];
      }
     } else {
      return item[element];
     }
    }, 0);
   }

   dataArr.splice(0, globalDataArray.length, globalObj);

   return dataArr;
  },
  getAllParametersFromKey(keys) {
   let arrayOfElements = [];
   keys.forEach((element, index) => {
    let splitEl = element.split(":");
    arrayOfElements.push(splitEl[1]);
   });
   arrayOfElements = [...new Set(arrayOfElements)];
   return arrayOfElements;
  },
  getAllElementsFromKeys(keys) {
   let elementsArr = [];
   keys.forEach((el, index) => {
    let elSplit = el.split(":");
    let element = elSplit[0];
    if (!elementsArr.includes(element) && element.length > 0) {
     elementsArr.push(element);
    }
   });
   return elementsArr;
  },
  async getQueueDetailsDataObjComparison(event) {
   this.isLoading = true;

   let dataArr = [];
   let arrOne = [];
   let arrTwo = [];
   if (this.pbxElements && event && event.length > 0) {
    // this.isLoading = true;

    for (let index = 0; index < this.comparePeriod.length; index++) {
     const elementPeriod = this.comparePeriod[index];
     for (let index = 0; index < event.length; index++) {
      const elementEvent = event[index];

      let data = await this.getQueueDetails(elementEvent, elementPeriod);

      if (data && Object.keys(data).length > 0) {
       let dataObj = {};

       let dataKeys = Object.keys(data);

       let arrayOfElements = this.getAllElementsFromKeys(dataKeys);

       let keysParameters = this.getAllParametersFromKey(dataKeys);

       if (this.selectedtime && this.selectedtime.length > 1) {
        let queueTime = undefined;
        let dataObj = {};
        let elementValue = 0;

        let firsQuarterHour =
         ("0" + (parseInt(this.selectedtime[0].slice(0, 2)) + parseInt(this.hostTimeZone))).slice(
          -2
         ) + this.selectedtime[0].slice(-2);

        let lastQuarterHour =
         ("0" + (parseInt(this.selectedtime[1].slice(0, 2)) + parseInt(this.hostTimeZone))).slice(
          -2
         ) + this.selectedtime[1].slice(-2);
        for (let index = 0; index < arrayOfElements.length; index++) {
         dataObj = {};
         const element = arrayOfElements[index];
         for (let index = 0; index < keysParameters.length; index++) {
          elementValue = 0;
          const keysParameter = keysParameters[index];
          if (element === "queue") {
           dataObj["element"] = event;
           dataObj["period"] = elementPeriod;
           dataKeys.forEach((el, index) => {
            let splitEl = el.split(":");
            let queueElement = splitEl[0];
            let queueDataType = splitEl[1];
            let elName = data[`${queueElement}:name`];
            let queuePeriod = splitEl[2] ? splitEl[2] : null;
            if (queuePeriod) {
             queueTime = queuePeriod.slice(-4);
            }
            if (splitEl.length >= 3) {
             if (element == queueElement) {
              if (queueDataType === keysParameter && queueTime) {
               if (
                parseInt(firsQuarterHour) <= parseInt(queueTime) &&
                parseInt(queueTime) <= parseInt(lastQuarterHour)
               ) {
                if (elementValue >= 0) {
                 elementValue = elementValue + parseInt(data[el]);
                 dataObj[queueDataType] = elementValue;
                } else {
                 dataObj[queueDataType] = 0;
                }
               }
              }
             }
            }
           });
          }
         }

         if (dataObj && dataObj.period) {
          if (dataObj.period == this.comparePeriod[0]) {
           arrOne.push(dataObj);
          } else {
           arrTwo.push(dataObj);
          }
         }
        }
       } else {
        dataObj = {};
        dataObj["element"] = event;
        dataObj["period"] = elementPeriod;
        dataKeys.forEach((el, index) => {
         let splitEl = el.split(":");
         let queueElement = splitEl[0];
         let queueDataType = splitEl[1];

         if (queueElement === "queue") {
          if (splitEl.length == 2) dataObj[queueDataType] = data[el];
         }
        });

        if (dataObj.period == this.comparePeriod[0]) {
         arrOne.push(dataObj);
        } else {
         arrTwo.push(dataObj);
        }
       }
      }

      // dataArr.push(dataObj);
     }
    }

    arrOne = this.reduceElementsComparisonObj(arrOne);
    arrTwo = this.reduceElementsComparisonObj(arrTwo);
    dataArr.splice(0, 1, arrOne[0]);
    dataArr.splice(1, 1, arrTwo[0]);
    // this.queueData = dataArr;
    this.queueDataArr = dataArr;
    this.getComparatorObj(dataArr);
   } else {
    this.queueDataArr = [];
    this.getComparatorObj(dataArr);
   }
   this.isLoading = false;
  },
  getComparatorKeys() {
   let keys = {};
   for (let index = 0; index < this.comparisonInfo.length; index++) {
    const element = this.comparisonInfo[index];
    const value = element.value;
    const name = element.name;
    if (element.show) {
     keys[value] = name;
    }
   }
   // let keys = {
   //   calls: "numberOfCalls",
   //   duration: "averageCallDuration",
   //   waiting_time_on_answer_in_queue: "averageWaitingTimeQueue",
   //   answered: "answeredCallsCoefficient",
   // };
   return keys;
  },
  getChangeIncreaseOrDecrease(value1, value2, type) {
   let value = value2 - value1;
   if (value > 0) {
    if (type === "calls" || type === "answered" || type === "answered_00_30") {
     return "increase";
    } else {
     return "increaseOposite";
    }
   } else {
    if (type !== "calls" && type !== "answered" && type !== "answered_00_30") {
     return "decreaseOposite";
    } else {
     return "decrease";
    }
   }
  },
  getComparatorObj(arr) {
   this.comparatorData = [];
   let comparatorObj = {};
   let comparators = this.getComparatorKeys();
   let keys = Object.keys(comparators);
   if (arr.length > 1) {
    keys.forEach((el, index) => {
     let statOne = undefined;
     let statTwo = undefined;
     if (
      !el.includes("calls") &&
      !el.includes("answered") &&
      !el.includes("redirected") &&
      !el.includes("abandoned") &&
      (!el.includes("duration") || el.includes("waiting"))
     ) {
      statOne = arr[0][String(el)] / arr[0]["calls"];
      statTwo = arr[1][String(el)] / arr[1]["calls"];
     } else if (
      !el.includes("calls") &&
      !el.includes("answered") &&
      !el.includes("redirected") &&
      !el.includes("abandoned") &&
      (el.includes("duration") || !el.includes("waiting"))
     ) {
      statOne = arr[0][String(el)] / arr[0]["answered"];
      statTwo = arr[1][String(el)] / arr[1]["answered"];
     } else if (
      !el.includes("calls") &&
      !el.includes("duration") &&
      !el.includes("redirected") &&
      !el.includes("answered_") &&
      !el.includes("waiting")
     ) {
      statOne = ((arr[0][String(el)] / arr[0]["calls"]) * 100).toFixed(1);
      statTwo = ((arr[1][String(el)] / arr[1]["calls"]) * 100).toFixed(1);
     } else {
      statOne = arr[0][String(el)];
      statTwo = arr[1][String(el)];
     }
     comparatorObj = {};
     comparatorObj["elementOne"] = arr[0]["element"];
     comparatorObj["elementTwo"] = arr[1]["element"];
     comparatorObj["name"] = comparators[String(el)];
     comparatorObj["statQueueOne"] = statOne;
     comparatorObj["statQueueTwo"] = statTwo;
     comparatorObj["change"] = this.getComparativeBetween(statOne, statTwo).toFixed(1) + "%";
     comparatorObj["changeType"] = this.getChangeIncreaseOrDecrease(statTwo, statOne, el);
     this.comparatorData.push(comparatorObj);
    });
   }
  },
  getComparativeBetween(value1, value2) {
   let comparativeValue = null;
   comparativeValue = (Math.abs(value1 - value2) * 100) / value2;
   return comparativeValue;
  },
  removeComparison(headerName, idx) {
   for (let index = 0; index < this.comparisonInfo.length; index++) {
    if (this.comparisonInfo[index].name.toLowerCase() === headerName.toLowerCase()) {
     this.comparisonInfo[index].show = !this.comparisonInfo[index].show;
    }
   }
   this.getComparatorObj(this.queueDataArr);
  },
  clearFilters() {
   for (let index = 0; index < this.comparisonInfo.length; index++) {
    this.comparisonInfo[index].show = false;
   }
   this.getComparatorObj(this.queueDataArr);
  },
  parseDateRange(date) {
   if (date.length === 8) {
    let year = ("0" + date).slice(1, -4);
    let month = ("0" + date).slice(-4, -2);
    let day = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = day + "/" + month + "/" + year;
    return String(fullDay);
   } else if (date.length === 6) {
    let year = ("0" + date).slice(1, 5);
    let month = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = month + "/" + year;
    return String(fullDay);
   } else if (date.length === 17) {
    let fullDay = date.split("-");
    let firstYear = ("0" + fullDay[0]).slice(1, 5);
    let firstMonth = ("0" + fullDay[0]).slice(-4, -2);
    let firstDay = ("0" + fullDay[0]).slice(-2);

    let firstFullDay = "";
    firstFullDay = firstDay + "/" + firstMonth + "/" + firstYear;

    let lastYear = ("0" + fullDay[1]).slice(1, 5);
    let lastMonth = ("0" + fullDay[1]).slice(-4, -2);
    let lastDay = ("0" + fullDay[1]).slice(-2);

    let lastFullDay = "";
    lastFullDay = lastDay + "/" + lastMonth + "/" + lastYear;
    let finalDay = "";
    finalDay = firstFullDay + " - " + lastFullDay;
    return String(finalDay);
   } else {
    return date;
   }
  },
  parseTimeRange(time) {
   if (time && time.length > 0) {
    const timeStart = time[0].slice(0, 2) + ":" + time[0].slice(2);
    const timeEnd = time[1].slice(0, 2) + ":" + time[1].slice(2);
    const finalTime = timeStart + " - " + timeEnd;
    return String(finalTime);
   }
  },
  currentMonthAndYearForPbx() {
   let options = { month: "short" };
   let month = String("0" + (new Date().getMonth() + 1)).slice(-2);
   let formattedMonth = new Intl.DateTimeFormat("fr-FR", options).format(month).slice(0, -2);
   this.textMonth = formattedMonth;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
  async test() {
   if (this.hostName && this.hostName !== "null") {
    try {
     const res = await axios.get(
      `${this.$cookie.getCookie("API")}/api/v1/pbxMap/${this.hostName}`,
      {
       headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
       },
      }
     );
     this.pbxMapData = res.data;
    } catch (error) {
     this.errorHandling(error);
    }
   }

   if (this.pbxElements && this.selectedElement.length > 0) {
    this.selectedQueue =
     this.selectedElement + " - " + this.pbxMapData.call_queues[this.selectedElement].name;
   }
  },
 },
 watch: {
  detailedCallStats: function (val) {
   if (this.selectedElement && this.selectedElement.length > 0) {
    this.getQueueDetailsDataObj(this.selectedElement);
    this.getQueueDetailsDataObjComparison(this.selectedElement);
   }
  },
  comparePeriodType: function (val) {
   this.setComparePeriod(this.period);
   if (this.selectedElement && this.selectedElement.length > 0) {
    this.getQueueDetailsDataObjComparison(this.selectedElement);
   }
  },
  selectedtime: function (val) {
   if (this.selectedElement && this.selectedElement.length > 0) {
    this.getQueueDetailsDataObj(this.selectedElement);
    this.getQueueDetailsDataObjComparison(this.selectedElement);
   }
  },
  period: function (val) {
   this.setComparePeriod(val);
   if (this.selectedElement && this.selectedElement.length > 0) {
    this.getQueueDetailsDataObj(this.selectedElement);
    this.getQueueDetailsDataObjComparison(this.selectedElement);
   }
  },
 },
 mounted() {
  this.$store.dispatch("pbxElements", null);
  if (!this.period && !this.pbxPeriodSelected) {
   this.period = this.currentMonthAndYearForPbx();
  }
  if (this.pbxPeriodSelected) this.period = this.pbxPeriodSelected;
  this.currentMonthAndYearForPbx();
  this.getButtonSelectedFromQueryPeriod();
  // if (this.pbxElements) {
  //   this.selectedElement = this.pbxElements;
  // }
 },
 computed: {
  ...mapGetters(["hostName", "hostTimeZone", "pbxPeriodSelected", "pbxElements"]),
 },
};
</script>

<style></style>
